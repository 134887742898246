export const environment = {
  production: true,
  environment: 'production',
  apiPath:
    'https://blankmile-api-production.jollywater-cbb0cfe0.northeurope.azurecontainerapps.io',
  storagePath: 'https://blankmileproduction.blob.core.windows.net',
  editorApiKey: 'tfr5o3dxkarb5gp2xobg5pwi4t4neh8o268slyz60oicv1wc',
  participantTimeout: 6000,
  stripePricingTableId: 'prctbl_1NQkk3LRVwUsaICQ8YU4MNXU',
  stripePublishableKey:
    'pk_live_51LkjLJLRVwUsaICQmI9NAUdg1hdaeBhMNYhgRCXcQbYl92m86V3iLfTsi3SeWBLDkCJYlwFLuSlDw1M4kcREk88m002TsEdT4h',
  auth: {
    authority: 'https://auth.blankmile.com',
    clientId: 'aidar-helper-client',
    clientRootTemplate: 'https://*.blankmile.com',
    clientScope: 'openid profile email',
    apiRoot:
      'https://blankmile-api-production.jollywater-cbb0cfe0.northeurope.azurecontainerapps.io',
  },
  useSentry: true,
  sentryDsn:
    'https://10f18aff40935407daa126094e8ce24d@o4508250093387776.ingest.de.sentry.io/4508256022429776',
  video: {
    logLevel: 'error',
  },
  testSoundFilePath:
    'https://blankmilepublicprod.blob.core.windows.net/sound-test-files/ringtone.wav',
};
